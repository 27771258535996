/* eslint-disable react/prop-types */
import React, { useContext, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import newApi from '../../../../../../common/api/newApi';
import styles from './WalletModal.module.scss';
import MetamaskIcon from '../../../../../../public/icons/svg/metamask.svg';
import DeSoIcon from '../desoIcon.svg';
import CoinbaseIcon from '../coinbaseIcon.svg';
import WalletConnectIcon from '../walletConnectIcon.svg';
import {
  desoLogin,
  ethLogin,
  ethSignUp,
  loadAccount,
} from '../../../../../../common/data/actions';
import { DesoContext } from '../../../../../contexts/DesoContext';
import {
  COLOUR_BLACK_5,
  COLOUR_BLACK_80,
  COLOUR_PRIMARY,
} from '../../../../../shared/colours';
import LoadingSpinner from '../../../../UI/LoadingSpinner/LoadingSpinner';
import { CloseIconGrey } from '../../../../../shared/libs/Icons';
import getOs from '../../../../../helpers/getOs';

const WalletModal = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.account.user);
  const lightMode = useSelector((state) => state.main.lightMode);
  const [loadingMM, setLoadingMM] = useState(false);
  const [loadingWC, setLoadingWC] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingCB, setLoadingCB] = useState(false);
  const [loadingDeSo, setLoadingDeSo] = useState(false);
  const { launch, jwt, supported } = useContext(DesoContext);
  const {
    redirect, showCloseIcon = true, onClose, hideHeader, showTerms,
  } = props;
  const isWebMobile = useRef(false);
  const { NEXT_PUBLIC_INFURA_ID } = process.env;

  const coinbaseWallet = new CoinbaseWalletSDK({
    appName: 'Entre',
    appLogoUrl: 'https://joinentre.com/logo.png',
    darkMode: false,
  });

  const AuthOption = ({ icon, text, onClick }) => (
    <button
      type="button"
      onClick={onClick}
      aria-label={text}
      className={styles.authOption}
    >
      {icon}
      <p className={styles.title}>{text}</p>
    </button>
  );

  // new coinbase wallets accounts
  const handleCoinbaseSignUp = async () => {
    try {
      setLoadingCB(true);
      let red = redirect;
      if (!red && window.location.search) {
        const params = new URLSearchParams(window.location.search);
        if (params) {
          red = params;
        }
      }
      const provider = coinbaseWallet.makeWeb3Provider(`https://mainnet.infura.io/v3/${NEXT_PUBLIC_INFURA_ID}`, 1);
      const accounts = await provider.enable();
      if (accounts) {
        const sign = await dispatch(ethSignUp(red, accounts, provider));
        if (sign) {
          setLoadingCB(false);
        }
      }
    } catch (error) {
      setLoadingCB(false);
      console.log(error);
    }
  };

  // new wallet connect wallets accounts
  const handleWalletConnectSignUp = async () => {
    try {
      setLoadingWC(true);
      localStorage?.removeItem('walletconnect');
      let red = redirect;
      if (!red && window.location.search) {
        const params = new URLSearchParams(window.location.search);
        if (params) {
          red = params;
        }
      }
      const provider = new WalletConnectProvider({
        infuraId: NEXT_PUBLIC_INFURA_ID,
      });
      const accounts = await provider.enable();
      if (accounts) {
        const sign = dispatch(ethSignUp(red, accounts, provider));
        if (sign) {
          setLoadingWC(false);
        }
      }
    } catch (error) {
      setLoadingWC(false);
      console.log(error);
    }
  };

  const hasMetamask = (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask);

  // new metamask wallet accounts
  const handleMetamaskSignUp = async () => {
    if (hasMetamask) {
      setLoadingMM(true);
      let red = redirect;
      if (!red && window.location.search) {
        const params = new URLSearchParams(window.location.search);
        if (params) {
          red = params;
        }
      }
      if (typeof window.ethereum !== 'undefined') {
        let provider = window.ethereum;
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.forEach(async (p) => {
            if (p.isMetaMask) {
              provider = p;
            }
          });
        }
        const res = await provider.request({
          method: 'eth_requestAccounts',
          params: [],
        });
        const sign = await dispatch(ethSignUp(red, res, provider));
        if (sign) {
          setLoadingMM(false);
        }
      }
    } else {
      Swal.fire({
        title: 'You do not have metamask installed, please try another way to continue.',
        icon: 'error',
        confirmButtonColor: COLOUR_PRIMARY,
        background: COLOUR_BLACK_80,
        color: COLOUR_BLACK_5,
      });
    }
  };

  const renderLoader = () => (
    <div className="main-loader">
      <div className="main-loader-logo">
        <img src={lightMode ? '/logo.png' : '/logo_dark.png'} alt="Entre" />
      </div>
      <LoadingSpinner />
    </div>
  );

  if (typeof window === 'undefined') {
    return renderLoader();
  }

  // used only for new deso wallet accounts
  const handleDeSoLogin = () => {
    let red = redirect;
    if (!red && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params) {
        red = params;
      }
    }
    dispatch(desoLogin(launch, jwt, red));
  };

  if (getOs() === 'Android' || getOs() === 'iOS') {
    isWebMobile.current = true;
  }

  // used only for linking deso wallet with existents entre accounts
  const linkDeso = () => {
    try {
      setLoadingDeSo(true);
      launch('log-in', { accessLevelRequest: 3 })
        .subscribe(async (res) => {
          await newApi.put('user', { deso: res.publicKeyAdded });
          setLoadingDeSo(false);
          onClose();
          dispatch(loadAccount(user.id));
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  // used only for linking metamask wallet with existents entre accounts
  const linkMetaMaskAccount = async () => {
    try {
      setLoadingMM(true);
      if (typeof window.ethereum !== 'undefined') {
        let provider = window.ethereum;
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.forEach(async (p) => {
            if (p.isMetaMask) provider = p;
          });
        }
        await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [{
            eth_accounts: {},
          }],
        });
        const res = await provider.request({
          method: 'eth_requestAccounts',
          params: [],
        });
        const account = res[0];
        const res1 = await newApi.get(`eth/sig?address=${account}`);
        if (res1.data) {
          const web3 = new Web3(provider);
          const signature = await web3.eth.personal.sign(res1.data.stringToSign, account);
          const res2 = await newApi.post('eth/sig-token', {
            address: account,
            signedMessage: signature,
          });
          if (res2.data) {
            const res4 = await newApi.put('user', { eth: account });
            if (res4) {
              setLoadingMM(false);
              if (token) {
                onClose();
              }
              dispatch(loadAccount(user.id));
            }
          }
        }
      }
    } catch (error) {
      setLoadingMM(false);
      toast.error(error.message);
    }
  };

  // used only for linking walletconnect with existents entre accounts
  const linkWalletConnectAccount = async () => {
    try {
      localStorage?.removeItem('walletconnect');
      const provider = new WalletConnectProvider({
        infuraId: NEXT_PUBLIC_INFURA_ID,
      });
      const accounts = await provider.enable();
      if (accounts) {
        setLoadingWC(true);
        const sign = await dispatch(ethLogin(accounts, provider));
        if (sign) {
          onClose();
        }
      }
      return false;
    } catch (error) {
      setLoadingWC(false);
      return console.log(error);
    }
  };

  // used only for linking walletconnect with existents entre accounts
  const linkCoinbaseAccount = async () => {
    try {
      const provider = coinbaseWallet.makeWeb3Provider(`https://mainnet.infura.io/v3/${NEXT_PUBLIC_INFURA_ID}`, 1);
      const accounts = await provider.enable();
      if (accounts) {
        setLoadingCB(true);
        const sign = await dispatch(ethLogin(accounts, provider));
        if (sign) {
          onClose();
        } else {
          setLoadingCB(false);
        }
      }
    } catch (error) {
      setLoadingCB(false);
      console.log(error);
    }
  };

  const MainComponent = () => (
    <div className={clsx(styles.wrapper, router.pathname !== '/' && styles.bordered)}>
      {!hideHeader ? (
        <header className={styles.header}>
          {showCloseIcon ? (
            <button
              className={styles.closeIcon}
              onClick={onClose}
              type="button"
            >
              {CloseIconGrey}
            </button>
          ) : null}
        </header>
      ) : null}
      <main>
        {!isWebMobile.current && hasMetamask ? (
          <AuthOption
            text={!loadingMM ? 'Metamask' : 'Sign message in wallet'}
            onClick={token ? linkMetaMaskAccount : handleMetamaskSignUp}
            icon={!loadingMM ? <MetamaskIcon /> : <LoadingSpinner size="small" />}

          />
        ) : null}
        <AuthOption
          text={!loadingWC ? 'WalletConnect' : 'Sign message in wallet'}
          onClick={token ? linkWalletConnectAccount : handleWalletConnectSignUp}
          icon={!loadingWC ? <WalletConnectIcon /> : <LoadingSpinner size="small" />}
        />
        <AuthOption
          text={!loadingCB ? 'Coinbase Wallet' : 'Sign message in wallet'}
          onClick={token ? linkCoinbaseAccount : handleCoinbaseSignUp}
          icon={!loadingCB ? <CoinbaseIcon /> : <LoadingSpinner size="small" />}
        />
        {supported
          ? (
            <AuthOption
              text={!loadingDeSo ? 'Deso' : 'Sign message in wallet'}
              onClick={token || user?.deso ? linkDeso : handleDeSoLogin}
              icon={!loadingDeSo ? <DeSoIcon /> : <LoadingSpinner size="small" />}
            />
          ) : null}
      </main>
      {showTerms ? (
        <footer className={styles.footer}>
          <p>
            By signing you accept The
            <a className={styles.textLink} target="_blank" href="https://joinentre.com/terms" rel="noreferrer">
              {' '}
              Terms of Services
              {' '}
            </a>
            and
            <a className={styles.textLink} target="_blank" href="https://joinentre.com/privacy" rel="noreferrer">
              {' '}
              Privacy Policy
            </a>
          </p>
        </footer>
      ) : null}
    </div>
  );

  return (
    <MainComponent />
  );
};

export default WalletModal;
